import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { url } from "./helper/UserRequest";
import { useAuthStore } from "../store/store"
import axios from "axios";

const Login = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { loginUrl, setUser, isLoggedIn, setIsLoggedIn, logoutUrl } =
    useAuthStore();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  
  const login = async () => {
    const res = await axios.post(loginUrl, formData).catch((err) => {
      errorHandle(err?.response?.data);
    });

    if (res) {
      setUser(res.data);
      setIsLoggedIn(true);

      toast.success("Successfully logged In", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      navigate("/dashboard");
    }
  };

  console.log(isLoggedIn);
  
  const logout = async () => {
    const res = await axios
      .get(logoutUrl, {
        withCredentials: true,
      })
      .catch((err) => {
        setIsLoggedIn(false);
        console.log(err, err?.response?.data);
      });

    if (res) {
      //  const data = await res?.data;
      console.log(res);
      setIsLoggedIn(false);
      // setIsLoggedIn(true);
      // setUser(data);
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleLogout = () => {
    setShowConfirmation(true);
  };

  const confirmLogout = () => {
    logout();
    setShowConfirmation(false);
    toast.success("Successfully logged out", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const cancelLogout = () => {
    setShowConfirmation(false);
  };

  return isLoggedIn ? (
    <div className="flex h-screen bg-black">
      <div
        className="w-full h-full opacity-20 bg-cover bg-center rounded-lg overflow-hidden relative"
        style={{
          backgroundImage: `url('https://source.unsplash.com/600x800/?business')`,
        }}
      ></div>
      <button
        style={{ zIndex: 3, marginTop: 60 }}
        onClick={handleLogout}
        className="bg-red-500 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 px-16 rounded-md"
      >
        Logout
      </button>

      {showConfirmation && (
        <div
          style={{ zIndex: 3 }}
          className="fixed inset-0 flex items-center justify-center bg-gray-900"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-lg text-red-400 mb-4">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-between">
              <button
                onClick={confirmLogout}
                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Yes
              </button>
              <button
                onClick={cancelLogout}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="flex items-center justify-center h-full w-full p-3 bg-white">
      <div className="bg-white w-full  rounded-lg flex flex-col md:w-1/2">
        <h2 className="text-2xl font-extraBold font-primary mb-4">
          {" "}
          L o g i n
        </h2>
        <form className="py-5 w-full my-14" onSubmit={handleSubmit}>
          <div className="mb-6">
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="py-4 pl-4  pr-12 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter your email"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="py-4 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter your password"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-5 rounded-md hover:bg-secondary focus:outline-none focus:ring focus:secondary"
          >
            Log In
          </button>
        </form>
        <p className="mt-4 text-center text-sm text-gray-600">
          Don't have an account?{" "}
          <a href="/register" className="text-blue-500 hover:underline">
            Sign up
          </a>
        </p>

        <button
          onClick={() => navigate("/forgotPassword")}
          className="my-10 py-4 bg-red-500 w-40 "
        >
          Forgot Password ?
        </button>
      </div>
    </div>
  );
};

export default Login;