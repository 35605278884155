import React from "react";
import "./TrackDesc.style.css";

function TrackDesc() {
  return (
    <main className="mt-10 flex flex-wrap justify-evenly">
      <section className="track-card p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col">
        <img className="trackimg" src={require("../../images/trackImg1.JPG")} />
        <div className="mt-5">
          <h4 className="font-primary text-center text-primary font-normal">
            Create an account
          </h4>
          <p className="text-secondary py-2 text-center text-xs">
            Build your reputation with a global profile that works across
            hundreds of different employers.
          </p>
        </div>
      </section>
      <section className="track-card p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col">
        <img className="trackimg" src={require("../../images/trackImg2.JPG")} />
        <div className="mt-5">
          <h4 className="font-primary text-center text-primary font-normal">
            Explore Your Options
          </h4>
          <p className="text-secondary py-2 text-center text-xs">
            Select your preferences (task, salary, location, etc) and discover
            the most relevant job for you
          </p>
        </div>
      </section>
      <section className="track-card mt-3 p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col">
        <img className="trackimg" src={require("../../images/trackImg3.JPG")} />
        <div className="mt-5">
          <h4 className="font-primary text-center text-primary font-normal">
            Talk On Your Terms
          </h4>
          <p className="text-secondary py-2 text-center text-xs">
            Message multiples employers while keeping all conversations one.
          </p>
        </div>
      </section>
    </main>
  );
}

export default TrackDesc;
