import React from "react";
import "./Course.style.css";
import { BsCodeSlash } from "react-icons/bs";
import { BiBarChartAlt } from "react-icons/bi";
import CourseCardList from "./CourseCardList";

function Course() {

  return (
    <main className="p-5 bg-white">
      <section className="text-center py-5">
        {/* <img  src={require("../../images/the-value-of-employee-training.jpg")} /> */}
        <div>
          <h1 className="text-primary p-2 text-xs">
            Welcome to{" "}
            <span className="text-2xl font-secondary font-bold p-1 text-secondary">
              Rama Dreams
            </span>{" "}
            job center.
          </h1>

          <p className="text-xs font-primary text-secondary px-3 font-hairline">
            we believe in empowering individuals to unlock their full potential
            and achieve their career aspirations.
          </p>
        </div>
      </section>

      <section className=" w-full mb-20 flex flex-row justify-center space-x-5">
        <div className="tech mt-5">
          <BsCodeSlash size={24} color="red" />
          <h1 className="text-xs font-bold p-3 text-primary">Tech</h1>
        </div>

        <div className="des mt-5">
          <img src={require("../../images/des.png")} style={{ width: 24 }} />
          <h1 className="text-xs font-bold p-3 text-primary">Design</h1>
        </div>

        <div className="mrk mt-5">
          <BiBarChartAlt size={24} className="text-primary" />

          <h1 className="text-xs font-bold p-3">Marketing</h1>
        </div>
      </section>

    <CourseCardList />
      
    </main>
  );
}

export default Course;
