import React, { useState } from "react";
import logo from "../images/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BiSolidUser} from "react-icons/bi";


const Nav = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <header className="sticky top-0 z-50 bg-white">
      <nav className="flex items-center bg-white justify-between w-full px-8 py-5 ">
        <div className="z-50 flex items-center justify-between w-full md:w-auto ">
          <img
            alt="logo"
            style={{ height: 40, width: 30 }}
            src={require("../images/rama.png")}
            width={80}
          />
          <div className="md:hidden" onClick={() => setOpen((open) => !open)}>
            {open ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
          </div>
        </div>

        <div className="hidden gap-8 md:flex">
          <NavLink
            className="font-primary text-xs"
            to={"/"}
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            Home
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/about"}
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            About us
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/services"}
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            Services
          </NavLink>

          <NavLink
            className="font-primary text-xs"
            to={"/dashboard"}
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
           Dashboard
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/contact"}
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            Contact us
          </NavLink>
        </div>

        <div className=" md:inline-block">
          <button className="bg-[#596e79] ml-2 " onClick={() => navigate("/login")}>
            <p className="text-secondaryx text-xs p-1 px-2 font-primary">
              <BiSolidUser />
            </p>
          </button>
        </div>

        {/* mobile nav */}
        <div
          className={`flex flex-col md:hidden py-24 absolute top-5 gap-8 px-10 w-full bg-slate-50 h-fit duration-500 ${
            open ? "left-0" : "-left-full"
          } transition-all`}
        >
          <NavLink
            className="font-primary text-xs"
            reloadDocument
            to={"/"}
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            Home
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/about"}
            reloadDocument
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            About us
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/services"}
            reloadDocument
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            Services
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/dashboard"}
            reloadDocument
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
           Dashboard
          </NavLink>
          <NavLink
            className="font-primary text-xs"
            to={"/contact"}
            reloadDocument
            style={({ isActive }) =>
              isActive ? { color: "#C7B198" } : { color: "#596E79" }
            }
          >
            Contact us
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
