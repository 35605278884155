import React from 'react'
import Card from '../../Jobs/Card';

function Tech() {
  return (
    <div>
        <Card />
    </div>
  )
}

export default  Tech;