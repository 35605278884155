import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthStore } from "../../store/store";

function JobDetails() {
  const { jobDetails } = useAuthStore();

  console.log(jobDetails);
  return (
    <main className="container mx-auto p-6 bg-[#fff]">
      <div className="flex flex-col md:flex-row items-center md:items-start md:justify-between">
        <div className="md:w-1/2">
          <img
            src={jobDetails[0].companyLogo}
            alt="Company Logo"
            className="w-full md:max-w-md mx-auto md:mx-0 mb-6 md:mb-0"
          />
        </div>
        <div className="md:w-1/2 md:pl-8">
          <h1 className="text-3xl font-bold text-primary">
            {" "}
            {jobDetails[0].company}{" "}
          </h1>
          <h2 className="text-2xl text-left font-semibold mb-4">
            {jobDetails[0].jobTitle}
          </h2>
          <div className="flex items-center mb-4">
            <span className="text-gray-600">Type:</span>
            <span className="ml-2 font-medium"> {jobDetails[0].ranges} </span>
          </div>
          <div className="flex items-center mb-4">
            <span className="text-gray-600">Job Category:</span>
            <span className="ml-2 font-medium">
              {jobDetails[0].jobCategory}
            </span>
          </div>
          <p className="text-gray-700 mb-6">{jobDetails[0].description}</p>
          <div className="flex flex-col md:flex-row items-left md:items-start md:space-x-4">
            <div className="mb-2 md:mb-0">
              <span className="text-gray-600 text-left">Location:</span>
              <span className="ml-2 font-medium text-left">
                {" "}
                {jobDetails[0].location}{" "}
              </span>
            </div>
            <div>
              <span className="text-gray-600">Posted:</span>
              <span className="ml-2 font-medium">{jobDetails[0].date}</span>
            </div>
          </div>

          <div className="my-3">
            <span className="text-gray-600 text-lg">
              Company email:{" "}
              <a
                className="underline text-blue"
                href="mailto:pacesetter@gmail.com"
              >
                {jobDetails[0].email}
              </a>{" "}
            </span>
          </div>
          <Link to={`/jobform/${jobDetails[0].jobId}`}>
            <button
              style={{
                backgroundColor: "#596E79",
                color: "#DFD3C3",
                fontSize: "15px",
                width: "50%",
                height: "50px",
                fontWeight: "600",
              }}
              className="mt-6 bg-primary hover:bg-secondary text-white py-4 px-4 rounded-md transition duration-300 ease-in-out"
            >
              Apply for this job
            </button>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default JobDetails;
