import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { GoVideo } from "react-icons/go";
import { CiTimer } from "react-icons/ci";

function CourseCard({ data }) {
  console.log(data);

  return (
    <div className="course-card flex flex-col items-center my-6">
      <div className="w-full relative">
        <div className="bg-secondaryp h-7 w-32 justify-center flex items-center mt-2 mr-2 shadow-lg absolute rounded-full top-0 right-0">
          <p className="text-primary font-primary text-center font-bold text-xs">
            {data.date}
          </p>
        </div>

        <div className="bg-white h-10 w-36 justify-center flex items-center  mr-2 shadow-lg absolute rounded-full top-10 right-0">
          <p className="text-primary font-primary text-center font-bold text-md">
            {data.lectures}
          </p>
        </div>

        <img
          className="w-full course-image"
          src={data.imageSrc}
          alt="Course Image"
        />
      </div>

      <div className="w-full p-3">
        <div className="flex flex-row mt-3 space-x-2">
          {data.stars.map((isFilled, index) => (
            <AiFillStar
              key={index}
              size={12}
              color={isFilled ? "gold" : "gray"}
            />
          ))}
        </div>
        <h1 className="font-primary py-2 text-md font-bold">{data.title}</h1>
      </div>

      <div className="w-full flex flex-row  my-1  px-3 items-center">
        <img
          className="w-7 h-7 rounded-full"
          src={data.instructorimg}
          alt="Instructor"
        />
        <div className="flex flex-row space-x-2 items-center">
          <p className="text-secondary text-xs pl-1"> By: </p>
          <p className="text-primary font-bold text-xs">{data.instructor}</p>
        </div>
      </div>

      <div className="w-full p-3 flex flex-row space-x-2 items-center">
        <div className="flex flex-row items-center">
          <CiTimer size={12} />
          <p className="text-xs text-primary pl-1"> {data.duration}</p>
        </div>{" "}
        <p className="px-3 text-primary">/</p>
        <div className="flex flex-row items-center">
          <GoVideo size={12} />
          <p className="text-xs text-primary pl-1"> {data.videoCount}</p>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
