import React from "react";
import about from "../images/about.png";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PiStarThin } from "react-icons/pi";
import { PiBagSimpleThin } from "react-icons/pi";
import { PiChatsCircleLight } from "react-icons/pi";
import { PiUserSquareThin } from "react-icons/pi";

const About_Us = () => {
  const navigate = useNavigate();
  return (
    <main className="min-h-screen bg-white ">
      <div style={{ position: "relative" }}>
        <img
          style={{ width: "100%" }}
          src={require("../images/Interviewing.jpeg")}
        />

        <section
          className="text-center bg-primary"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <p className="font-primary text-sm font-bold text-white">About us</p>
          <h1 className="font-hairline text-xs font-bold text-secondaryx">
            We help your career grow and develop
          </h1>
        </section>
      </div>

      <section className="px-3 mt-10  items-center flex flex-col md:flex-row md:justify-evenly">
        <div className="p-3 w-full md:w-1/3 md:mb-0 flex flex-col">
          <h2 className="text-primary font-primary text-lg py-2 mx-auto text-center">
            Guiding You Towards Your Career Aspiration
          </h2>
          <h4 className="w-full text-secondary pb-0 text-xs mx-auto text-justify font-hairline">
            Welcome to Rama Dreams - your ultimate career solution! We are a
            Skilled Migration and Sponsored career recruitment business based in
            London, ENG, dedicated to helping professionals from across the
            globe find their dream job in the UK. Our experienced team of
            experts understand the complexities and challenges of finding the
            right job, and are committed to guiding you towards your career
            aspirations.
          </h4>
        </div>

        <div className="p-3 w-full md:w-1/3 md:mb-0 flex flex-col">
          <h2 className="text-primary font-primary text-lg py-2 mx-auto text-center">
            Turning Your Career Aspirations Into Reality
          </h2>
          <h4 className="w-full text-secondary pb-0 text-xs mx-auto text-justify font-hairline">
            We understand that finding the right job can be a challenging and
            overwhelming process, especially when you're seeking to move to a
            new country. But, we are here to change that. We believe in dreaming
            big and finding unique ways to reach success, and that's exactly
            what we will help you do. Our team of experienced immigration and
            recruitment specialists is dedicated to guiding you through the
            complex process of finding your dream job in the UK.
          </h4>
        </div>
      </section>

      <section className=" px-0  items-center flex flex-col md:flex-row md:justify-evenly">
        <div className="p-3 w-full md:w-1/3 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 50 }}
            src={require("../images/abtimg.JPG")}
          />
        </div>

        <div className="p-3 w-full md:w-1/3 md:mb-0 flex flex-col">
          <h1 className="text-2xl font-primary font-normal text-center text-primary pb-10">
            Perfect for Candidate. Beautiful for Employers
          </h1>

          <section className="flex items-center flex-wrap justify-evenly">
            <div className=" w-40 p-3 w-full md:w-1/2 md:mb-0 flex flex-col">
              <div>
                <PiStarThin size={40} className="font-primary text-primary" />

                <h1 className="text-1xl py-2 font-primary font-normal text-primary">
                  Profile Highlighters
                </h1>
                <h1 className="text-xs font-primary font-normal text-gray-300">
                  Get Highlighted by the Company you've been worked
                </h1>
              </div>
              W
            </div>

            <div className=" w-40 p-3 w-full md:w-1/2 md:mb-0 flex flex-col">
              <div>
                <PiBagSimpleThin
                  size={40}
                  className="font-primary text-primary"
                />

                <h1 className="text-1xl py-2 font-primary font-normal text-primary">
                  Career Booster
                </h1>
                <h1 className="text-xs font-primary font-normal text-gray-300">
                  Boost your career journey faster than before
                </h1>
              </div>
            </div>
          </section>

          <section className=" flex items-center flex-wrap justify-evenly ">
            <div className=" w-40 p-3 w-full md:w-1/2 md:mb-0 flex flex-row">
              <div>
                <PiChatsCircleLight
                  size={40}
                  className="font-primary text-primary"
                />

                <h1 className="text-1xl py-2 font-primary font-normal text-primary">
                  Interactive Assessment
                </h1>
                <h1 className="text-xs font-primary font-normal text-gray-300">
                  Work on the interactive assessment give by the company
                </h1>
              </div>
            </div>

            <div className=" w-40 p-3 w-full md:w-1/2 md:mb-0 flex flex-row">
              <div>
                <PiUserSquareThin
                  size={40}
                  className="font-primary text-primary"
                />

                <h1 className="text-1xl py-2 font-primary font-normal text-primary">
                  Featured Profile
                </h1>
                <h1 className="text-xs font-primary font-normal text-gray-300">
                  Being featured makes your profile stands out from others
                </h1>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="px-3 mt-10  items-center flex flex-col md:flex-row md:justify-evenly">
        <div className="p-3 w-full md:w-1/3 md:mb-0 flex flex-col">
          <h2 className="text-primary font-primary text-lg py-2 mx-auto text-center">
            Your Career Dreams, Our Priority
          </h2>
          <h4 className="w-full text-secondary pb-0 text-xs mx-auto text-justify font-hairline">
            At Rama Dreams, we are dedicated to ensuring your success in finding
            the perfect career path in the UK. Our team of experts possess a
            wealth of knowledge and experience in the field of immigration and
            recruitment, making us the go-to solution for job seekers and
            employers alike. We prioritize your dreams and goals, and with our
            comprehensive range of services, we'll work with you every step of
            the way to help you make the move to the UK.
          </h4>
        </div>

        <div className="p-3 pb-10 w-full md:w-1/3 md:mb-0 flex flex-col">
          <h2 className="text-primary font-primary text-lg py-2 mx-auto text-center">
            Our Services - Made Just For You
          </h2>
          <h4 className="w-full text-secondary pb-0 text-xs mx-auto text-justify font-hairline">
            With our expertise and resources, we prioritize helping you make the
            move to the UK and find the job of your dreams. Our range of
            services includes Job Board Posting Service, Immigration Consulting,
            Trainings and Courses, Job Placement Services, and Job Search
            Assistance. Whether you are an employer looking for the right
            candidates, or a job seeker looking for the perfect job, we've got
            you covered. Our immigration consulting services provide the support
            and guidance you need to apply for visas, permanent residency, and
            citizenship, allowing you to start a new life in a new country with
            confidence. Our job placement services help you find employment
            opportunities in your local area, while our job search assistance
            provides the resources and support you need to find the right job.
          </h4>
        </div>
      </section>

      <div className="flex justify-end flex-1 w-full px-10 my-8">
        <button
          className=" flex flex-row justify-center items-center hover:brightness-110 hover:animate-pulse font-bold py-3 px-6 rounded-full bg-gradient-to-r from-primary to-secondary text-white"
          onClick={() => navigate("/services")}
        >
          <p className="font-bold font-primary text-secondary text-xs">
            Check out our Services{" "}
          </p>
          <BsArrowRightShort />
        </button>
      </div>
    </main>
  );
  W;
};

export default About_Us;

//https://dribbble.com/shots/22259908-WorkHive-Minimalist-Job-Portal-Landing-Page
