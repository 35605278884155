import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { PiBagSimpleLight } from "react-icons/pi";
import { BiRightArrowAlt } from "react-icons/bi";
import { MdCategory } from "react-icons/md";
import JobCard from "../components/Jobs/JobCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthStore } from "../store/store";
import { toast } from "react-toastify";
export default function Search() {
  const navigate = useNavigate();
  const { getJobUrl, jobs, user, isLoggedIn, setJobs, getlistOfJobUrl } =
    useAuthStore();

  const jobType = ["Full-time", "Part-time", "Contract"];
  const [searchTerm, setSearchTerm] = useState("");
  const [ranges, setRanges] = useState("");
  const [category, setCategory] = useState("");
  const [country, setCountry] = useState("");

  const jobCategory = [
    "Tech",
    "Health",
    "Finance",
    "Marketing",
    "Design",
    "Education",
    "Customer Service",
    "Human Resources",
    "Sales",
    "Manufacturing",
    "Hospitality",
    "Legal",
    "Media and Communication",
    "Science",
    "Social Services",
    "Transportation",
    "Construction",
    "Agriculture",
    "Nonprofit",
    "Government",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Fitness and Recreation",
    "Fashion",
    "Food and Beverage",
    "Environment and Sustainability",
    "Entertainment",
    "Automotive",
    "Pharmaceutical",
    "Insurance",
    "Logistics",
    "Consulting",
    "Research and Development",
    "Sports",
    "Art and Creative",
    "Writing and Editing",
    "Event Planning",
    "Beauty and Wellness",
    "Travel and Tourism",
    "Utilities",
    "E-commerce",
    "Cybersecurity",
    "Biotechnology",
    "Data Science",
    "Blockchain",
    "Augmented Reality/Virtual Reality",
    "Robotics",
  ];

  const countryOptions = [
    "Select your country",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo (Congo-Kinshasa)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const listDate = {
    userID: user?.user?._id,
    location: country,
    category,
    ranges,
  };
  useEffect(() => {
    const storedLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn && !storedLoggedIn) {
      navigate("/login");
      if (!toast.isActive("notLoggedInToast")) {
        errorHandle(
          "You are not logged in, Please Login or Create an Account to continue"
        );
      }
    }
  }, [isLoggedIn, navigate]);
  useEffect(() => {
    getAllJobs();
  }, [isLoggedIn, user]);
  useEffect(() => {
    getlistOfJobs();
  }, [ranges, category, country]);

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const getAllJobs = async () => {
    const res = await axios
      .post(getJobUrl, { userID: user?.user?._id })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {
      setJobs(res.data);
    }
  };
  const getlistOfJobs = async () => {
    const res = await axios.post(getlistOfJobUrl, listDate).catch((err) => {
      errorHandle(err?.response?.data);
    });

    if (res) {
      setJobs(res.data);
    }
  };

  return (
    <main className="h-full">
      <div style={{ zIndex: 3 }} className="w-full px-5 mb-5  relative">
        <input
          type="text"
          placeholder="Search for your dream job..."
          className="border text-xs font-primary shadow-lg rounded px-5 py-5 w-full bg-white"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center mr-2">
          <button className="bg-primary rounded px-3 py-3 text-white focus:outline-none">
            <BiSearch color="white" className="h-5 w-5" />
          </button>
        </div>
      </div>
      <section
        className="p-5 mt-6 flex md:flex-row md:items-center justify-center space-x-5"
        style={{ zIndex: 3 }}
      >
        <div className="flex items-center md:mb-0 relative">
          <select
            onChange={(e) => setRanges(e.target.value)}
            className="border text-xs font-primary rounded px-5 py-3 w-full bg-secondaryx placeholder-primary pl-10"
          >
            <option value="" disabled selected>
              Select Job Type
            </option>
            {jobType.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-transparent">
            <PiBagSimpleLight className="h-4 w-4 text-primary" />
          </span>
        </div>

        <div className="flex items-center relative">
          <select
            onChange={(e) => setCountry(e.target.value)}
            className="border text-xs font-primary rounded px-5 py-3 w-full bg-secondaryx placeholder-primary pl-10"
          >
            <option value="" disabled selected>
              Select Location
            </option>
            {countryOptions.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-transparent">
            <SlLocationPin className="h-4 w-4 text-primary" />
          </span>
        </div>

        <div className="flex items-center md:mb-0 relative">
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="border text-xs font-primary rounded px-5 py-3 w-full bg-secondaryx placeholder-primary pl-10"
          >
            <option value="" disabled selected>
              Select Job Type
            </option>
            {jobCategory.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-transparent">
            <MdCategory className="text-primary" />
          </span>
        </div>
      </section>
      {jobs.length === 0 ? (
        <h1>Loading</h1>
      ) : (
        jobs
          ?.filter((data) => {
            if (searchTerm == "") {
              return data;
            } else if (
              data?.title?.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return data;
            }
          })
          .map((job, index) => (
            <div
              className={`p-4 sm:w-full md:w-auto order-${index} sm:order-none`}
              key={job._id}
            >
              <JobCard
                key={job._id}
                jobId={job._id}
                userId={job.userID}
                companyLogo={job.logo}
                date={job.date}
                company={job.name}
                jobTitle={job.title}
                jobRanges={job.ranges}
                jobCategory={job.category}
                location={job.location}
                description={job.description}
                email={job.email}
                ranges={job.ranges}
              />
            </div>
          ))
      )}
    </main>
  );
}
