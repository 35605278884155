import React from "react";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className="w-full py-5 bg-[#2C363D] ">
      <div className="grid justify-center grid-cols-1 gap-3 px-5 md:grid-cols-4">
        <div
          className="flex justify-center mt-5 mb-3 rounded-full items-center"
          style={{ width: 80, height: 80, backgroundColor: "#ffffff" }}
        >
          <img
            alt="logo"
            style={{
              height: 50,
              width: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            src={require("../images/rama.png")}
          />
        </div>

        <div className="mt-5">
          <h3 className="mb-4 text-secondary text-md font-primary text-md">
            About
          </h3>
          <div className="flex flex-col gap-2">
            <h4 className="text-white text-xs font-primary font-hairline ">
              Job Placement
            </h4>
            <h4 className="text-white text-xs font-primary font-hairline ">
              Recruitment
            </h4>
            <h4 className="text-white text-xs font-primary font-hairline ">
              Job Search
            </h4>
          </div>
        </div>
        <div className="mt-5">
          <h3 className="mb-4 text-secondary text-md font-primary text-md  ">
            Services
          </h3>
          <div className="flex flex-col gap-2">
            <h4 className="text-white text-xs font-primary font-hairline ">
              Job Search Assistance
            </h4>
            <h4 className="text-white text-xs font-primary font-hairline ">
              Job Board Posting
            </h4>
            <h4 className="text-white text-xs font-primary font-hairline ">
              Job Placement Services
            </h4>
            <h4 className="text-white text-xs font-primary font-hairline ">
              Trainings and Courses
            </h4>
            <h4 className="text-white text-xs font-primary font-hairline ">
              Immigration Consulting
            </h4>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="mb-4 text-secondary text-md font-primary text-md ">
            Contact Us
          </h3>
          <div className="flex flex-col gap-2">
            <Link
              className="text-white text-xs font-primary font-hairline "
              to="/contact"
            >
              Send a message
            </Link>
          </div>
        </div>
      </div>

      <div className="flex mt-20 justify-center w-full my-9">
        <p className="text-center text-sm text-secondaryz">
          Copyright Knowledge &#169;{date}. <br /> All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
