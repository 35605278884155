import "./DemandJob.style.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function DemandJob() {
  const [touchHover, setTouchHover] = useState(false);

  const handleTouchStart = () => {
    setTouchHover(true);
  };

  const handleTouchEnd = () => {
    setTouchHover(true);
  };

  const navigate = useNavigate();

  return (
    <section className="demand py-10 bg-secondaryp" style={{ zIndex: 3 }}>
      <h1 className="text-primary font-primary text-2xl font-bold px-10 text-center">
        Most Demand Job Categories
      </h1>

      <section className="mt-10 flex flex-wrap justify-evenly">
        
        <div onClick={() => navigate('tech')}
          className={`demand-card1 ${
            touchHover ? "touch-hover" : ""
          } p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handleTouchStart}
          onPointerUp={handleTouchEnd}
        >
          <div style={{ position: "absolute", top: 100 }}>
            <h1 className="text-14 font-primary font-normal text-white">
            Tech Career Development
            </h1>
            <h1 className="text-xs font-primary text-secondaryx">
              New 102 jobs posted
            </h1>
          </div>
        </div>

        <div onClick={() => navigate('tech')}
          className={`demand-card2 ${
            touchHover ? "touch-hover" : ""
          } p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handleTouchStart}
          onPointerUp={handleTouchEnd}
        >
          <div style={{ position: "absolute", top: 100 }}>
            <h1 className="text-14 font-primary font-normal text-white">
            Creative & Design
            </h1>
            <h1 className="text-xs font-primary text-primary">
              New 76 jobs posted
            </h1>
          </div>
        </div>

        <div onClick={() => navigate('marketing')}
          className={`demand-card3 ${
            touchHover ? "touch-hover" : ""
          } p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handleTouchStart}
          onPointerUp={handleTouchEnd}
        >
          <div style={{ position: "absolute", top: 100 }}>
            <h1 className="text-14 font-primary font-normal text-white">
              Sales & Marketing
            </h1>
            <h1 className="text-xs font-primary text-secondaryx">
              New 81 jobs posted
            </h1>
          </div>
        </div>
      </section>

      <section className="mt-5 flex flex-wrap justify-evenly">
      <div onClick={() => navigate('tech')}
          className={`demand-card4 ${
            touchHover ? "touch-hover" : ""
          } p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handleTouchStart}
          onPointerUp={handleTouchEnd}
        >
          <div style={{ position: "absolute", top: 100 }}>
            <h1 className="text-14 font-primary font-normal text-white">
            Health Opportunity
            </h1>
            <h1 className="text-xs font-primary text-secondaryx">
              New 102 jobs posted
            </h1>
          </div>
        </div>

        <div onClick={() => navigate('tech')}
          className={`demand-card5 ${
            touchHover ? "touch-hover" : ""
          } p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handleTouchStart}
          onPointerUp={handleTouchEnd}
        >
          <div style={{ position: "absolute", top: 100 }}>
            <h1 className="text-14 font-primary font-normal text-white">
              Farming
            </h1>
            <h1 className="text-xs font-primary text-secondary">
              New 14 jobs posted
            </h1>
          </div>
        </div>

        <div onClick={() => navigate('tech')}
          className={`demand-card6 ${
            touchHover ? "touch-hover" : ""
          } p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handleTouchStart}
          onPointerUp={handleTouchEnd}
        >
          <div style={{ position: "absolute", top: 100 }}>
            <h1 className="text-14 font-primary font-normal text-[#fff]">
              Finance
            </h1>
            <h1 className="text-xs font-primary text-primary">
              New 12 jobs posted
            </h1>
          </div>
        </div>
      </section>

    </section>
  );
}

export default DemandJob;
