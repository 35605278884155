import React, { useEffect, useState } from "react";
import JobCard from "../Jobs/JobCard";
import axios from "axios";
import { useAuthStore } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function PostPage() {


  const jobCategories = [
    "Tech",
    "Healthcare",
    "Finance",
    "Marketing",
    "Design",
    "Education",
    "Customer Service",
    "Human Resources",
    "Sales",
    "Manufacturing",
    "Hospitality",
    "Legal",
    "Media and Communication",
    "Science",
    "Social Services",
    "Transportation",
    "Construction",
    "Agriculture",
    "Nonprofit",
    "Government",
    "Real Estate",
    "Retail",
    "Telecommunications",
    "Fitness and Recreation",
    "Fashion",
    "Food and Beverage",
    "Environment and Sustainability",
    "Entertainment",
    "Automotive",
    "Pharmaceutical",
    "Insurance",
    "Logistics",
    "Consulting",
    "Research and Development",
    "Sports",
    "Art and Creative",
    "Writing and Editing",
    "Event Planning",
    "Beauty and Wellness",
    "Travel and Tourism",
    "Utilities",
    "E-commerce",
    "Cybersecurity",
    "Biotechnology",
    "Data Science",
    "Blockchain",
    "Augmented Reality/Virtual Reality",
    "Robotics",
  ];
  const countryOptions = [
    "Select your country",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo (Congo-Kinshasa)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  // }, [])


  const navigate = useNavigate();

  const now = new Date();

  const { postJobUrl, isLoggedIn } = useAuthStore();

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn && !storedLoggedIn) {
      navigate("/login");
      if (!toast.isActive("notLoggedInToast")) {
        toast.error(
          "You are not logged in, Please Login or Create an Account to continue",
          {
            toastId: "notLoggedInToast",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressClassName: "toast-progress",
            className: "toast-error",
            bodyClassName: "toast-body",
          }
        );
      }
    }
  }, [isLoggedIn, navigate]);

  const [formData, setFormData] = useState({
    companyLogo: null,
    companyName: "",
    jobTitle: "",
    location: "",
    description: "",
    ranges: "",
    email: "",
    jobCategory: "",
    date: String(now.toLocaleDateString(undefined, { dateStyle: "medium" })),
  });
 

  const [postedJob, setPostedJob] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    // Handle file input separately
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name === "ranges") {
      // Change this to match the correct name
      setFormData({ ...formData, ranges: value });
    } else if (name === "jobCategory") {
      setFormData({ ...formData, jobCategory: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    post();
    console.log(formData);
  };

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const post = async () => {
    toast.info("🦄 Posting job.... Please wait!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    const res = await axios
      .post(postJobUrl, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {
      console.log(res.data);
      navigate("/jobs");

      toast.success("Job successfully posted", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="job-posting-page bg-white p-5 flex justify-center items-center flex-col flex-center ">
      <div className="md:w-1/2 p-4">
        <h1 className="text-2xl font-primary mb-4">
          Connect with Top Talent: Craft Your Job Posting
        </h1>
        <p className="text-gray-600 text-sm font-primary mb-6">
          Attract the best candidates for your team! Share the details of your
          job opening and let us help you find the perfect match. Describe the
          role, highlight key responsibilities, and showcase your company's
          culture. It's time to build your dream team!
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <h6 className="my-2 text-[#999] text-md">Company Logo ⬇️</h6>
            <input
              type="file"
              id="companyLogo"
              name="companyLogo"
              onChange={handleChange}
              accept="image/*" // Accept only image files
              className="w-full border border-gray-300 p-2 rounded-md"
              placeholder="Company Logo"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              placeholder="Company Name"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              required
              placeholder="Job Title"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div className="md:w-1/2 p-4">
          </div>
        {/* ... (rest of your JSX code remains unchanged) */}
        <div className="mb-4">
          {/* <label htmlFor="jobCategory" className="my-2 text-[#999] text-md">
            Job Category ⬇️
          </label> */}
          <select
            id="jobCategory"
            name="jobCategory"
            value={formData.jobCategory}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          >
            <option value="" disabled>Select Job Category</option>
            {jobCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          {/* <label htmlFor="location" className="my-2 text-[#999] text-md">
            Country ⬇️
          </label> */}
          <select
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          >
            <option value="" disabled>Select Country</option>
            {countryOptions.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Email"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div className="mb-4">
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              placeholder="Job Description"
              className="w-full border border-gray-300 p-2 rounded-md"
            />
          </div>
          <div className="mb-4">
            <select
              id="jobType"
              name="ranges"
              value={formData.ranges}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded-md"
            >
              <option disabled value=""> Select job type </option>
              <option value="Full-time"> Full-time </option>
              <option value="Part-time"> Part-time </option>
              <option value="Contract"> Contract</option>
            </select>
          </div>
          <button
            type="submit"
            className="bg-primary text-white text-xs text-uppercase font-bold font-bold py-4 px-4 rounded-lg"
          >
            SUBMIT JOB POSTING
          </button>
        </form>

        {postedJob && (
          <div className="posted-job mt-6">
            <h2 className="text-lg font-semibold mb-2">Posted Job:</h2>
            {postedJob}
          </div>
        )}
      </div>
    </div>
  );
}

export default PostPage;
