import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthStore } from "../store/store";
function NewPassword() {
  
  const navigate = useNavigate();

  let { email } = useParams();

  const { resetPassword, forgotPassword } = useAuthStore();

  const [counter, setCounter] = useState(60);
  const [isRunning, setIsRunning] = useState(true);
  const [otp, setOtp] = useState("");
  const [enterEmail, setEnterEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  useEffect(() => {
    setEnterEmail(email);
  }, [email]);

  useEffect(() => {
    let intervalId = null;

    if (isRunning) {
      document.getElementById("resend").style.display = "none";
      document.getElementById("diV").style.display = "block";
      intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          const newCounter = prevCounter - 1;

          if (newCounter === 0) {
            setIsRunning(false);
            document.getElementById("resend").style.display = "block";
            document.getElementById("diV").style.display = "none";
          }

          return newCounter;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSuccess = (suc) => {
    toast.success(suc, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = async () => {
    const formData = {
      email,
      otp,
      newPassword,
    };

    if (newPassword !== confirmNewPassword) {
      errorHandle("New password is not the same as confirm password");
    } else if (otp.length !== 4) {
      errorHandle("Otp code length should be 4");
    } else {
      const res = await axios.post(resetPassword, formData).catch((err) => {
        errorHandle(err?.response?.data);
      });

      if (res) {
        // setUser(res.data);
        handleSuccess("Password changed successfully");
        console.log(res.data);
        navigate("/login");
      }
      console.log(formData);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setCounter(60);
    setIsRunning((isRunning) => !isRunning);
    // using an HTTP request
    resendEmailVerification();
  };

  const resendEmailVerification = async () => {
    toast.info("🦄 Please Wait!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    const res = await axios
      .post(forgotPassword, { email: email })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {
      // setUser(res.data);
      handleSuccess("Otp has been resent to your email");
      console.log(res.data);
      // navigate(`/newPassword/${inputEmail}`);
    }
  };

  return (
    <main className="h-screen w-full bg-black ">
      <div
        className="w-full h-full opacity-40 bg-cover bg-center rounded-lg overflow-hidden relative"
        style={{
          backgroundImage: `url('https://source.unsplash.com/600x800/?business')`,
        }}
      ></div>

      <div class="bg-white h-85 mt-20 w-96 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 rounded-md shadow-lg">
        <h1 class="text-primary text-lg mb-5 font-semibold ">
          Change Password
        </h1>
        <input
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          class="text-sm p-3 border-2 border-gray-300 rounded-md w-full mb-4"
          placeholder="Enter Otp Code"
        />
        <input
          value={enterEmail}
          onChange={(e) => {
            setEnterEmail(e.target.value);
          }}
          disabled={true}
          class="text-sm p-3 border-2 border-gray-300 rounded-md w-full mb-4"
          placeholder="Enter your email"
        />
        <input
        type="password"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          class="text-sm p-3 border-2 border-gray-300 rounded-md w-full mb-4"
          placeholder="New Password"
        />
        <input
        type="password"
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value);
          }}
          class="text-sm p-3 border-2 border-gray-300 rounded-md w-full mb-4"
          placeholder="Confirm Password"
        />
        <div className="flex  justify-between">
          <button
            onClick={handleSubmit}
            class="bg-primary w-36 text-white py-2 px-6 rounded-md hover:bg-primary-dark transition duration-300 ease-in-out"
          >
            Confirm
          </button>

   
        </div>

        <form onClick={handleFormSubmit} className="mt-4">
            <p className="text-gray-600">
              Didn't receive the email?
              <button
                id="resend"
                type="submit"
                className="ml-1 text-blue-500 underline hover:text-blue-600 transition duration-300"
              >
                Resend
              </button>
            </p>
          </form>

          <div className="mt-4" id="diV">
            In <b className="text-red-500" id="number"> {counter} </b> second(s)
          </div>
      </div>
    </main>
  );
}

export default NewPassword;
