import React, { useRef, useState, useEffect } from "react";
import { useAuthStore } from "../../store/store";
import convertToBase64 from "../../auth/helper/ConvertImage"
import { MdEdit } from "react-icons/md"
import JobCard from "../Jobs/JobCard"
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"

function Dashboard() {

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("postedJobs")

  const {
    user,
    updateProfilePicture,
    updateProfileUrl,
    isLoggedIn,
    getYourJobsUrl,
    setJobs,
    jobs,
    getJobUrl,
  } = useAuthStore();

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(user?.user?.profilePicture);
  const [isUploading, setIsUploading] = useState(false);

  console.log(user);

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn && !storedLoggedIn) {
      navigate("/dashboard");
      if (!toast.isActive("notLoggedInToast")) {
        toast.error(
          "You are not logged in, Please Login or Create an Account to continue",
          {
            toastId: "notLoggedInToast",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressClassName: "toast-progress",
            className: "toast-error",
            bodyClassName: "toast-body",
          }
        );
      }
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    setPreviewImage(user?.user?.profilePicture);
  }, [user?.user?.profilePicture]);
  useEffect(() => {
    getYourAllJobs();
  }, []);

  const handleProfilePictureChange = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);

    setSelectedFile(file);
    setPreviewImage(base64);
  };

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const uploadProfilePicture = async () => {
    toast.info("🦄 Please Wait!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    console.log(updateProfileUrl);

    const res = await axios
      .post(
        updateProfileUrl,
        { userID: user?.user?._id, profile: selectedFile },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {
      toast.success(res.data.success, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      navigate("/dashboard");
    }
  };

  const getYourAllJobs = async () => {
    const res = await axios
      .post(getYourJobsUrl, { userID: user?.user?._id })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {
      setJobs(res.data);
    }
  };
  const getAllJobs = async () => {
    try {
      const res = await axios.post(getJobUrl, { userID: user?.user?._id });
      setJobs(res?.data);
    } catch (err) {
      errorHandle(err?.response?.data);
    }
  };

  return (
    <div className="h-full">
      {isLoggedIn ? (
        <div className=" user-dash-header w-full items-center justify-between p-6 flex flex-row ">
          <div className="relative">
            <button
              className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-2 bg-secondary text-white rounded-full p-2 z-10"
              onClick={handleProfilePictureChange}
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : <MdEdit />}
            </button>
            <input
              type="file"
              accept="image/*"
              name="profile"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
              disabled={isUploading}
            />
            <div className="relative">
              <div className="overflow-hidden rounded-lg w-20 h-20 object-cover md:w-20 md:h-20 lg:w-20 lg:h-20">
                <img
                  src={previewImage}
                  alt="User Avatar"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="md:ml-4 md:order-1">
            <p className="text-secondary text-xs">
              Embark on your journey here.
            </p>
            <h1 className="text-lg font-primary text-secondaryp font-bold">
              {`${user?.user?.firstName} ${user?.user?.lastName}`}
            </h1>
            <p className="text-secondary text-xs">{user?.user?.email}</p>
            {selectedFile && (
              <button
                className="bg-secondary text-white rounded px-4 py-2 mt-2"
                onClick={uploadProfilePicture}
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Upload"}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <h1 className="text-3xl font-semibold text-red-500 font-primary">
            Access Denied
          </h1>
          <p className="text-lg text-gray-600 mb-4">
            Please login to your account to continue.
          </p>
          <button
            onClick={() => navigate("/login")}
            className="bg-primary w-60 text-white rounded px-6 py-5 mt-5 hover:bg-secondary focus:outline-none focus:ring focus:border-secondaryp"
          >
            Login
          </button>
        </div>
      )}

      <div className="flex h-full justify-around my-10">
        <button
          className={`p-5 border-4 focus:outline-none ${
            activeTab === "appliedJobs"
              ? "bg-primary text-white"
              : "bg-secondary px-10 text-gray-700"
          }`}
          onClick={() => {
            setActiveTab("appliedJobs");
            getAllJobs();
          }}
        >
          Jobs
        </button>

        <button
          className={`p-5 border-4 mr-4 focus:outline-none ${
            activeTab === "postedJobs"
              ? "bg-primary text-white"
              : "bg-secondary px-10 text-gray-700"
          }`}
          onClick={() => setActiveTab("postedJobs")}
        >
          <section className="h-full">
            <h1>Jobs Posted</h1>
          </section>
        </button>
      </div>

      <div className="job-lists h-screen mt-4">
        {activeTab === "postedJobs" && (
          <div className="h-screen">
            <h2 className="text-lg font-semibold mb-2">Posted Jobs</h2>
            {jobs.map((job, index) => (
              <section className="h-full">
                <div
                  className={`p-4 sm:w-full md:w-auto order-${index} sm:order-none`}
                  key={job._id}
                >
                  <JobCard
                    key={job._id}
                    jobId={job._id}
                    userId={job.userID}
                    companyLogo={job.logo}
                    date={job.date}
                    company={job.name}
                    jobTitle={job.title}
                    jobRanges={job.ranges}
                    jobCategory={job.category}
                    location={job.location}
                    description={job.description}
                    email={job.email}
                    ranges={job.ranges}
                  />
                </div>
              </section>
            ))}
          </div>
        )}
        {activeTab === "appliedJobs" && (
          <div className="h-screen">
            <h2 className="text-lg font-semibold mb-2"> Jobs</h2>
            {jobs.map((job, index) => (
              <div
                className={`p-4 sm:w-full md:w-auto order-${index} sm:order-none`}
                key={job._id}
              >
                <JobCard
                  key={job._id}
                  jobId={job._id}
                  userId={job.userID}
                  companyLogo={job.logo}
                  date={job.date}
                  company={job.name}
                  jobTitle={job.title}
                  jobRanges={job.ranges}
                  jobCategory={job.category}
                  location={job.location}
                  description={job.description}
                  email={job.email}
                  ranges={job.ranges}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;