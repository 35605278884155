import React from "react"
import Form from "../components/Form"

const Contact_Us = () => {
  return (
    <main className="min-h-screen bg-white">
      <div className="w-full pt-10 ">
        <h2 className=" text-primary font-bold font-primary p-3">
          REACH OUT TO US{" "}
        </h2>
      </div>

      <div className="p-3 mt-10  flex flex-col md:flex-row md:justify-evenly  items-center">
        <section className="p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <div
            className=""
            // data-aos="zoom-out-up"
          >
            <Form />
          </div>
        </section>

        <section className="p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 16 }}
            src={require("../images/contact_us.png")}
          />
        </section>
      </div>
      <script src="/js"></script>
    </main>
  );
};

export default Contact_Us;