import React from 'react'
import Card from '../../Jobs/Card';

function Finance() {
  return (
    <div>
        <Card />
    </div>
  )
}

export default  Finance;