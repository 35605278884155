import React from "react";
import "./Jobs.style.css";
import JobCard from "./JobCard";
import Card from "./Card";

function Jobs() {
  return (
    <main data-aos="zoom-out"  className="job-main p-5">
      <section className="mt-5 flex flex-wrap items-center justify-around">
        <div
          style={{ width: 300 }}
          className=" md:w-1/2 mb-4 md:mb-0 flex flex-col"
        >
          <button
            onClick={() => navigate("/jobs")}
            className="home-btn "
            style={{
              backgroundColor: "#596E79",
              color: "#ffffff",
              borderRadius: "0.25rem",
              fontWeight: "800",
              fontSize: "15px",
              margin: "5px",
              width: "140px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="ml-1 text-xs  text-white font-primary">
              View All Listing
            </p>
          </button>
        </div>

        <div
          style={{ width: 300 }}
          className="  md:w-1/2 mb-4 md:mb-0 flex flex-col"
        >
          <h1 className="text-2xl font-primary text-primary font-normal">
            Newest Job Listing
          </h1>
          <p className="text-secondary font-primary text-xs mt-2">
            Narrow down your applications and let the appearing and matching
            companies shows off
          </p>
        </div>
      </section>
      <div className=" mt-10 flex flex-col md:flex-row md:items-center justify-center text-center">
        <a href="" className="underline font-primary text-primary text-md p-2">
          All Categories
        </a>
        <a href="" className="underline font-primary text-primary text-md p-2">
          Design
        </a>
        <a href="" className="underline font-primary text-primary text-md p-2">
          Technology
        </a>
        <a href="" className="underline font-primary text-primary text-md p-2">
          Marketing
        </a>
      </div>

      <Card />
    </main>
  );
}

export default Jobs;
