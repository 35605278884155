import React, { useEffect } from "react";
import JobCard from "./JobCard";
import axios from "axios";
import { useAuthStore } from "../../store/store";
import { toast } from "react-toastify";

function Card() {
  const { postUrl, getJobUrl, jobs, user, isLoggedIn, setJobs } = useAuthStore();

  useEffect(() => {
    getAllJobs();
  }, [isLoggedIn, user]);

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const getAllJobs = async () => {
    try {
      const res = await axios.post(getJobUrl, { userID: user?.user?._id });
      setJobs(res.data);
    } catch (err) {
      errorHandle(err?.response?.data);
    }
  };

  if (jobs.length === 0) {
    return <h1>Loading</h1>;
  } else {
    return (
      <main className="p-4 md:p-20">
        <section className="mt-6 grid gap-6 md:grid-cols-3">
          {jobs.map((job, index) => (
            <div
              key={job._id}
              className="p-4 sm:w-full md:w-auto md:order-none"
            >
              <JobCard
                jobId={job._id}
                userId={job.userID}
                companyLogo={job.logo}
                date={job.date}
                company={job.name}
                jobTitle={job.title}
                jobRanges={job.ranges}
                jobCategory={job.category}
                location={job.location}
                description={job.description}
                email={job.email}
                ranges={job.ranges}
              />
            </div>
          ))}
        </section>
      </main>
    );
  }
}

export default Card;
