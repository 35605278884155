import rep1 from "../images/rep1.png";
import rep2 from "../images/rep2.png";
import join_us from "../images/join us.png";
import services from "../images/services.png";
import Form from "../components/Form";
import "../style.css";
import { SiGooglemarketingplatform } from "react-icons/si";
import { FcBusiness } from "react-icons/fc";
import { BiSearch } from "react-icons/bi";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { PiBagSimpleLight } from "react-icons/pi";
import { BiRightArrowAlt } from "react-icons/bi";
import DemandJob from "../components/DemandJob/DemandJob";
import { useNavigate } from "react-router-dom";
import TrackDesc from "../components/TrackDesc/TrackDesc";
import { MdCategory } from "react-icons/md";
import { useState } from "react";
import Startup from "../components/Startup/Startup";

export default function Home() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  return (
    <main className="flex home-main flex-col items-center min-h-screen w-full">
      <div
        className="container mx-auto px-5 py-10 flex flex-col md:flex-row items-center justify-between"
        style={{ zIndex: 3 }}
      >
        <div className="md:w-1/2 md:order-2 lg:ml-10 md:ml-10">
          <h1 className="text-5xl text-primary leading-14 font-bold">
            One Step Closer to Your Dream Job
          </h1>
          <p className="mt-3 text-sm text-primary md:text-left">
            Ramadreams is the one-stop solution for sponsored jobs abroad and
            the best guide to healthcare business startup in the UK.
          </p>
          <p className="mt-6 text-lg text-secondary font-light leading-relaxed">
            Explore thousands of job opportunities and manage all your job
            applications from start to finish. Your future awaits – come find
            it.
          </p>
        </div>

        <div className="w-full md:w-1/2 md:order-1 mt-5">
          <img
            alt="Representation"
            className="w-full md:w-full mx-auto md:w-3/4 rounded-full animate-fade-in"
            src={require("../images/beab6129abea011a891497c3324fae61.gif")}
          />
        </div>
      </div>

      <div className="w-full px-5 mb-5 relative">
        <button
          onClick={() => navigate("/search")}
          className="w-full bg-white rounded-full border-2 p-5"
        >
          <p className="text-secondary text-left">
            Explore available job offers
          </p>
        </button>
      </div>

      <div
        style={{ zIndex: 3 }}
        className="my-4  flex flex-wrap justify-evenly "
      >
        <button
          onClick={() => navigate("/jobs")}
          className="home-btn bg-primary hover:bg-primary-900 text-white font-bold py-2 px-4 rounded-full animate-pulse"
          style={{
            color: "#ffffff",
            borderRadius: "0.25rem",
            fontWeight: "800",
            fontSize: "15px",
            margin: "5px",
            width: "140px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiRightArrowAlt />
          <p className="ml-1 text-xs  text-white font-primary">Jobs</p>
        </button>
        <button
          onClick={() => navigate("/post")}
          className="home-btn bg-primary hover:bg-primary-900 text-white font-bold py-2 px-4 rounded-full animate-pulse"
          style={{
            color: "#ffffff",
            borderRadius: "0.25rem",
            fontWeight: "800",
            fontSize: "15px",
            margin: "5px",
            width: "140px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiRightArrowAlt />
          <p className="ml-1 text-xs  text-white font-primary"> Post job</p>
        </button>
{/* 
        <button
          onClick={() => navigate("/startup")}
          className="home-btn bg-primary hover:bg-primary-900 text-white font-bold py-2 px-4 rounded-full animate-pulse"
          style={{
            color: "#ffffff",
            borderRadius: "0.25rem",
            fontWeight: "800",
            fontSize: "15px",
            margin: "5px",
            width: "140px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiRightArrowAlt />
          <p className="ml-1 text-xs  text-white font-primary"> Startups </p>
        </button> */}
      </div>

<div style={{ zIndex: 3 }}
        className="w-full">
  
<Startup />
</div>


      <DemandJob />

      <section
        className="mt-20 flex p-5 flex-col justify-center items-center"
        style={{ zIndex: 3 }}
      >
        <h1 className="text-2xl text-primary px-5 text-center font-primary font-normal">
          The First Track to your Next Jobs
        </h1>
        <p className="font-hairline text-lg  text-center py-2 text-secondary">
          we ensure your next step towards your dream job is a step forward,{" "}
          that's why we built a jobs marketplace that cut off the edges of the
          boring process
        </p>

        <TrackDesc />
      </section>

      <section style={{ zIndex: 3 }} className="mt-20 mb-20">
        <div className="p-3 w-full md:w-1/3 mb-4 md:mb-0 flex flex-col">
          <button
            style={{ width: 250, height: 50, borderRadius: 100 }}
            className=" flex flex-row justify-center items-center hover:brightness-110 hover:animate-pulse font-bold py-3 px-6 rounded-full bg-gradient-to-r from-primary to-secondary text-white"
            onClick={() => navigate("/jobs")}
          >
            <p
              style={{ fontWeight: 700 }}
              className="text-white text-xs p-2 font-primary"
            >
              EXPLORE JOB OPPORTUNITIES
            </p>

            <BsFillArrowUpRightSquareFill className="bg-secondary" />
          </button>
        </div>
      </section>
    </main>
  );
}
