import React, { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/store";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JobCard({
  companyLogo,
  date,
  company,
  jobTitle,
  jobCategory,
  location,
  description,
  userId,
  jobId,
  ranges,
  email,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setJobDetails, user, deleteJobUrl, setJobs } = useAuthStore();

  const setAndMove = async () => {
    setIsLoading(true);
    await setJobDetails([
      {
        jobId: jobId,
        userId: userId,
        companyLogo: companyLogo,
        date: date,
        jobTitle: jobTitle,
        location: location,
        description: description,
        jobCategory: jobCategory,
        company: company,
        email: email,
        ranges: ranges,
      },
    ]);
    setIsLoading(false);
    navigate("/jobDetails");
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const confirmDelete = () => {
    console.log("Job deleted successfully");

    setShowConfirmation(false);

    deleteJob();
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
  };

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const deleteJob = async () => {
    const res = await axios
      .post(deleteJobUrl, { userID: user?.user?._id, jobId: jobId })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });
    if (res) {
      setJobs(res.data);
      toast.success("Successfully deleted", {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <main className="flex flex-col items-center justify-center">
      <section>
        <div data-aos="zoom-in" className="job-card">
          <div className="flex flex-row mt-3 mb-2 justify-between">
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: "#000",
                borderRadius: 20,
              }}
              className=""
            >
              <img
                style={{ width: 20, height: 20, borderRadius: 20 }}
                src={companyLogo}
                alt={`${company} Logo`}
              />
            </div>

            <div className="flex flex-row justify-center items-center">
              <BiTimeFive size={12} />
              <p className="font-primary text-xs text-primary ml-1">{date}</p>
            </div>
            {user?.user?._id === userId && showConfirmation ? (
              <div className="flex items-center space-x-2">
                <button
                  onClick={confirmDelete}
                  className="bg-red-500 p-1 rounded mb-1"
                >
                  <MdDelete color="#fff" />
                </button>
                <button
                  onClick={cancelDelete}
                  className="bg-gray-500 p-1 rounded mb-1"
                >
                  Cancel
                </button>
              </div>
            ) : (
              user?.user?._id === userId && (
                <button
                  onClick={handleDelete}
                  className="bg-red-500 p-1 rounded mb-1"
                >
                  <MdDelete color="#fff" />
                </button>
              )
            )}
          </div>

          <h1 className="text-lg py-3 font-primary font-normal text-primary">
            {company}
          </h1>

          <h1 className="text-md font-primary font-normal text-secondary">
            {jobTitle}
          </h1>

          <div className="flex flex-row items-center justify-start mt-3 space-x-3">
            <h2 className="text-xs font-primary text-primary font-bold bg-[#FBF5F5] border rounded-full p-2">
              {ranges}
            </h2>
            <h2 className="text-xs font-primary text-primary font-bold bg-[#F3F2E7] border rounded-full p-2">
              {jobCategory}
            </h2>
          </div>

          <h1 className="text-lg pt-5 font-primary font-normal text-primary">
            {location}
          </h1>

          <p
            style={{ fontSize: 10 }}
            className="font-hairline text-xs py-3 text-primary multiline-text"
          >
            {description.slice(0, 150)}
            {description.length > 150 ? "..." : ""}
          </p>

          <div className="flex flex-row mt-3 items-center justify-start space-x-3">
            {isLoading ? ( // Conditional rendering for loading indicator
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                onClick={setAndMove}
                className="home-btn rounded-full"
                style={{
                  backgroundColor: "#596E79",
                  color: "#DFD3C3",
                  fontSize: "15px",
                  width: "100%",
                  height: "50px",
                  fontWeight: "600",
                }}
              >
                Apply for this job
              </button>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobCard;