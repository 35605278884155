import React, { useState } from "react";
import { useAuthStore } from "../../store/store";
import axios from "axios";
import { toast } from "react-toastify";

export default function StartupForm() {
  
  const { StartupFormUrl } = useAuthStore();

  const [companyName, setCompanyName] = useState("");
  const [typeOfHealthcare, setTypeOfHealthcare] = useState("");
  const [companyDocument, setCompanyDocument] = useState(null);
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [desc, setDesc] = useState("");

  let formField = {
    companyName: companyName,
    typeOfHealthcare: typeOfHealthcare,
    companyDocument: companyDocument,
    companyEmail: companyEmail,
    companyPhoneNumber: companyPhoneNumber,
    desc: desc,
  };

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const formData = async () => {
    const response = await axios
      .post(StartupFormUrl, formField, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (response) {
      toast.success("Success! We've Received Your Form Submission.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData();
  };

  return (
    <div className=" w-full lg:p-10">
      <form className=" mx-5 ">
        <input
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          className="p-5 my-2"
          placeholder="Company Name"
        />
        <input
          value={typeOfHealthcare}
          onChange={(e) => setTypeOfHealthcare(e.target.value)}
          className="p-5 my-3"
          placeholder="Type of healthcare"
        />

        <label
          className="block text-primary text-sm font-medium mb-2"
          htmlFor="companyDoc"
        >
          Upload Document
        </label>
        <input
          type="file"
          id="companyDocument"
          name="companyDocument"
          onChange={(e) => setCompanyDocument(e.target.files[0])}
          accept=".pdf, .doc, .docx"
          className="px-4 py-5 rounded-lg border-gray-300 bg-gray-50 placeholder-gray-500 focus:outline-none focus:border-primary"
        />

        <input
          value={companyEmail}
          onChange={(e) => setCompanyEmail(e.target.value)}
          type="email"
          className="p-5 my-3"
          placeholder="Email"
        />
        <input
          value={companyPhoneNumber}
          onChange={(e) => setCompanyPhoneNumber(e.target.value)}
          type="phone"
          className="p-5 my-3"
          placeholder="Phone No."
        />

        <textarea
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          className="p-5 text-primary"
          placeholder="Startup descriptions "
        />

        <div className="flex justify-center item-center">
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-primary w-74 hover:bg-secondary text-white py-3 px-6 rounded-md mt-6 transition duration-300 ease-in-out"
          >
            Submit Application
          </button>
        </div>
      </form>
    </div>
  );
}
