import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "../src/pages/Home";
import About_Us from "./pages/About_Us";
import Contact_Us from "./pages/Contact_Us";
import Services from "../src/pages/Services";
import SharedLayout from "./components/SharedLayout";
import Error from "./components/Error";
import Verification from "./auth/Verification";

import AOS from "aos";
import "aos/dist/aos.css";
import Register from "./auth/Register";
import Login from "./auth/Login";
import Jobs from "./components/Jobs/Jobs";
import Tech from "./components/JobCategory/Tech/Tech";
import Finance from "./components/JobCategory/Finance/Finance";
import Marketing from "./components/JobCategory/Marketing/Marketing";
import Dashboard from "./components/Dashboard/Dashboard";
import PostPage from "./components/PostPage/PostPage";
import Course from "./components/Course/Course";
import Sidebar from "./components/Dashboard/Sidebar";
import Settting from "./components/Dashboard/DashboardCategories/Settting";
import { Settings } from "lucide-react";
import JobDetails from "./components/Jobs/JobDetails";
import JobApplicationForm from "./components/Jobs/JobApplicationForm";
import ForgotPassword from "./auth/ForgotPassword";
import NewPassword from "./auth/NewPassword";
import ConfirmCode from "./auth/ConfirmCode";
import Search from "./pages/Search";
import Startup from "./components/Startup/Startup";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SharedLayout />,
    children: [
      { element: <Navigate to="/app" replace />, index: true },
      { path: "app", element: <Home /> },
      { path: "about", element: <About_Us /> },
      { path: "contact", element: <Contact_Us /> },
      { path: "services", element: <Services /> },
      { path: "jobs", element: <Jobs /> },
      { path: "tech", element: <Tech /> },
      { path: "finance", element: <Finance /> },
      { path: "post", element: <PostPage /> },
      { path: "course", element: <Course /> },
      { path: "startup", element: < Startup /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "search", element: <Search /> },
      { path: "sidebar", element: <Sidebar /> },
      { path: "setting", element: <Settings /> },
      { path: "forgotPassword", element: < ForgotPassword /> },
      { path: "newPassword/:email", element: < NewPassword /> },
      { path: "confirmCode", element: < ConfirmCode /> },

      { path: "jobDetails", element: <JobDetails /> },
      { path: "jobform/:id", element: <JobApplicationForm /> },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
  { path: "*", element: <Navigate to="/404" replace /> },

  {
    path: "/verification",
    element: <Verification />,
  },
]);

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return <RouterProvider router={router}></RouterProvider>;
};

export default App;
