import React from "react";
import { useNavigate } from "react-router-dom";

function ConfirmCode() {
  const navigate = useNavigate();

  return (
    <main className="h-screen w-full bg-black ">
      <div
        className="w-full h-full opacity-40 bg-cover bg-center rounded-lg overflow-hidden relative"
        style={{
          backgroundImage: `url('https://source.unsplash.com/600x800/?business')`,
        }}
      ></div>

      <div class="bg-white h-60 mt-20 w-96 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 rounded-md shadow-lg">
        <h1 class="text-primary text-lg mb-5 font-semibold ">
          Confirm new login email
        </h1>
        <input
          class="text-sm p-3 border-2 border-gray-300 rounded-md w-full mb-4"
          placeholder="Enter Code"
        />
        <div className="flex justify-between">
          <button
            onClick={() => navigate("/newPassword")}
            class="bg-primary w-36 text-white py-2 px-6 rounded-md hover:bg-primary-dark transition duration-300 ease-in-out"
          >
            Confirm
          </button>

          <button
            onClick={() => navigate("/newPassword")}
            class="bg-red-500 w-32 text-white py-2 px-6 rounded-md hover:bg-primary-dark transition duration-300 ease-in-out"
          >
            Resend
          </button>
        </div>
      </div>
    </main>
  );
}

export default ConfirmCode;