import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthStore } from "../../store/store";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function JobApplicationForm() {

  const navigate = useNavigate();

  let { id } = useParams();
  const { applyJobUrl } = useAuthStore();
  const now = new Date();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [country, setCountry] = useState("");

  let fields = {
    jobId: id,
    fullName: fullName,
    email: email,
    phoneNumber: phoneNumber,
    uploadResume: resume,
    coverLetter: coverLetter,
    portfolioLink: portfolioLink,
    yearsOfExperience: yearsOfExperience,
    country: country,
    date: String(now.toLocaleDateString(undefined, { dateStyle: "medium" })),
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    apply();
  };

  const errorHandle = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const apply = async () => {

    const res = await axios
      .post(applyJobUrl, fields, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => {
        errorHandle(err?.response?.data);
      });

    if (res) {

      toast.success("Application Submitted Successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      
      navigate("/jobs");
      console.log(res.data);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-400 via-white-500 to-white-500 p-5 py-20">
      <form className="max-w-2xl w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-primary text-center text-gray-800 mb-6">
          Job Application
        </h2>
        <div className="grid grid-cols-1 gap-4">
          {/* Placeholder attributes added instead of labels */}
          <input
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
            required
          />
          <input
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
            required
          />
          <input
            type="file"
            name="uploadResume"
            placeholder="Upload Resume"
            onChange={(e) => setResume(e.target.files[0])}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
            required
          />
          <textarea
            placeholder="Cover Letter"
            value={coverLetter}
            onChange={(e) => setCoverLetter(e.target.value)}
            className="w-full border rounded-md px-3 py-2 h-32 resize-none focus:outline-none focus:ring focus:border-primary"
            required
          ></textarea>
          <input
            type="url"
            placeholder="Portfolio Link"
            value={portfolioLink}
            onChange={(e) => setPortfolioLink(e.target.value)}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
          />
          <input
            type="number"
            placeholder="Years of Experience"
            value={yearsOfExperience}
            onChange={(e) => setYearsOfExperience(e.target.value)}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
            min="0"
            max="50"
          />
          <input
            type="text"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-primary"
            required
          />
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-primary hover:bg-secondary text-white py-3 px-6 rounded-md mt-6 w-full transition duration-300 ease-in-out"
        >
          Submit Application
        </button>
      </form>
    </div>
  );
}

export default JobApplicationForm;

// https://www.behance.net/gallery/101137321/Email-app/modules/582976353
