import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { GoVideo } from "react-icons/go";
import { CiTimer } from "react-icons/ci";
import CourseCard from './CourseCard';

function CourseCardList() {
  const courseData = [
    {
      date: "17 March, 2023",
      lectures: "72 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/1.jpg",
      stars: [true, true, true, true, false],
      title: "Frontend Developer",
      instructor: "Amalia John",
      duration: "5 Hrs",
      videoCount: 50,
      instructorimg: "https://www.gstatic.com/webp/gallery/1.jpg",
    },
    {
      date: "09 August, 2023",
      lectures: "32 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/2.jpg",
      stars: [true, true, true, false, false],
      title: "Account Management",
      instructor: "Joy Cameroon",
      duration: "3 Hrs",
      videoCount: 40,
      instructorimg: "https://www.gstatic.com/webp/gallery/2.jpg",
    },
    {
      date: "21 June, 2023",
      lectures: "50 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/3.jpg",
      stars: [true, true, true, true, true],
      title: "Full Stack Developer",
      instructor: "John Smith",
      duration: "6 Hrs",
      videoCount: 60,
      instructorimg: "https://www.gstatic.com/webp/gallery/3.jpg",
    },
    {
      date: "14 April, 2023",
      lectures: "45 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/4.jpg",
      stars: [true, true, false, false, false],
      title: "Marketing Specialist",
      instructor: "Laura Johnson",
      duration: "4 Hrs",
      videoCount: 35,
      instructorimg: "https://www.gstatic.com/webp/gallery/4.jpg",
    },

    {
      date: "17 March, 2023",
      lectures: "72 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/1.jpg",
      stars: [true, true, true, true, false],
      title: "Frontend Developer",
      instructor: "Amalia John",
      duration: "5 Hrs",
      videoCount: 50,
      instructorimg: "https://www.gstatic.com/webp/gallery/1.jpg",
    },
    {
      date: "09 August, 2023",
      lectures: "32 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/2.jpg",
      stars: [true, true, true, false, false],
      title: "Account Management",
      instructor: "Joy Cameroon",
      duration: "3 Hrs",
      videoCount: 40,
      instructorimg: "https://www.gstatic.com/webp/gallery/2.jpg",
    },
    {
      date: "21 June, 2023",
      lectures: "50 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/3.jpg",
      stars: [true, true, true, true, true],
      title: "Full Stack Developer",
      instructor: "John Smith",
      duration: "6 Hrs",
      videoCount: 60,
      instructorimg: "https://www.gstatic.com/webp/gallery/3.jpg",
    },
    {
      date: "14 April, 2023",
      lectures: "45 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/4.jpg",
      stars: [true, true, false, false, false],
      title: "Marketing Specialist",
      instructor: "Laura Johnson",
      duration: "4 Hrs",
      videoCount: 35,
      instructorimg: "https://www.gstatic.com/webp/gallery/4.jpg",
    },

    {
      date: "17 March, 2023",
      lectures: "72 Lectures",
      imageSrc: "https://www.gstatic.com/webp/gallery/1.jpg",
      stars: [true, true, true, true, false],
      title: "Frontend Developer",
      instructor: "Amalia John",
      duration: "5 Hrs",
      videoCount: 50,
      instructorimg: "https://www.gstatic.com/webp/gallery/1.jpg",
    },

    // Add more course data objects here if needed
  ];
  
  
  

  console.log(courseData);
  return (
    <main>
      <section className="flex md:px-20  flex-wrap justify-center lg:justify-evenly" >
        {courseData.map((data, index) => (
          <CourseCard   key={index} data={data} /> 
        ))}
      </section>
    </main>
  );
}

export default CourseCardList;
