import React from "react";
import service from "../images/service.png";
import { RiCheckboxBlankFill } from "react-icons/ri";

const Services = () => {

  return (
    <main className="min-h-screen ">
      <div style={{ position: "relative" }}>
        <img style={{ width: "100%" }} src={require("../images/srvimg.jpeg")} />

        <section
          className="text-center bg-primary"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <p className="font-primary text-sm font-bold text-white">
            Learn more about our services
          </p>
          <h1 className="font-hairline text-xs font-bold text-secondaryx">
            Understanding the strategy behind the service at Rama dreams.
          </h1>
        </section>
      </div>

 

      <section className="p-3 mt-10   items-center flex flex-col md:flex-row md:justify-evenly  items-center">
        <div className=" p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <div>
            <h1 className="text-1xl py-2 font-primary font-normal text-primary">
              Job Board Posting
            </h1>
            <h1 className="text-xs font-hairline text-justify font-hairline text-primary">
              In today's fast-paced and competitive job market, finding the
              right candidates for your organization can be a challenging task.
              At Rama dreams, we understand the importance of attracting top
              talent to drive your business's success. Our Job Board Posting
              Service is designed to provide employers with a powerful platform
              to showcase their job opportunities on multiple job boards,
              thereby reaching a broader audience and connecting with the most
              qualified candidates. With our Job Board Posting Service,
              employers can leverage our extensive network of job boards and
              online platforms, tailored to various industries and job
              categories. Our team of experienced recruiters and digital
              marketing experts carefully curate the job listings to ensure they
              are strategically placed on the most relevant and high-traffic job
              boards.
            </h1>
          </div>
        </div>

        <div className=" p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 10 }}
            src={require("../images/srv1.jpg")}
          />
        </div>
      </section>

      <section className="p-3 mt-0   items-center flex flex-col md:flex-row md:justify-evenly  items-center">
        <div className=" p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <div>
            <h1 className="text-1xl py-2 font-primary font-normal text-primary">
              Immigration Consulting
            </h1>
            <h1 className="text-xs font-hairline text-justify font-hairline text-primary">
              At Rama Dreams, we believe in the power of dreams and the pursuit
              of a better life. Our Immigration Consulting service is dedicated
              to helping individuals and families make life-changing moves by
              providing comprehensive support and guidance throughout the
              immigration process. Moving to a new country can be a
              transformative experience, filled with new opportunities, cultural
              experiences, and personal growth. However, navigating the
              complexities of immigration laws and procedures can be daunting,
              especially when dealing with various visa categories, permanent
              residency applications, and citizenship processes. That's where
              our team of immigration experts comes in. Our experienced
              immigration consultants have an in-depth understanding of the
              ever-evolving immigration landscape and are equipped with the
              latest knowledge and insights to ensure a smooth and successful
              immigration journey. Whether you are seeking to reunite with
              family members, explore career opportunities abroad, or simply
              embark on a new adventure, our consultants are here to guide you
              every step of the way.
            </h1>
          </div>
        </div>

        <div className="  p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 10 }}
            src={require("../images/srv.jpg")}
          />
        </div>
      </section>

      <section className="p-3 mt-0   items-center flex flex-col md:flex-row md:justify-evenly  items-center">
        <div className=" p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <div>
            <h1 className="text-1xl py-2 font-primary font-normal text-primary">
              Training and Courses
            </h1>
            <h1 className="text-xs font-hairline text-justify font-hairline text-primary">
              At Rama Dreams, we believe in empowering individuals to unlock
              their full potential and achieve their career aspirations. In
              addition to our Job Placement and Immigration Services, we offer a
              comprehensive range of Training and Courses that cater to
              individuals from diverse backgrounds and industries. Our Training
              and Courses are meticulously designed to provide valuable
              insights, practical skills, and industry-relevant knowledge.
              Whether you are a recent graduate seeking to kickstart your career
              or a seasoned professional looking to enhance your expertise, we
              have a variety of training programs to suit your specific needs
              and goals. Upskilling and continuous learning are vital components
              of career growth and adaptability in today's dynamic job market.
              Our training programs are led by experienced instructors and
              industry experts who are passionate about sharing their knowledge
              and expertise. We ensure that our course content is up-to-date,
              reflecting the latest trends and developments in various
              industries.
            </h1>
          </div>
        </div>

        <div className="  p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 10 }}
            src={require("../images/srv3.jpg")}
          />
        </div>
      </section>

      <section className="p-3 mt-0   items-center flex flex-col md:flex-row md:justify-evenly  items-center">
        <div className=" p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <div>
            <h1 className="text-1xl py-2 font-primary font-normal text-primary">
              Job Search Assistance
            </h1>
            <h1 className="text-xs font-hairline text-justify font-hairline text-primary">
              At Rama Dreams, we understand that embarking on a job search can
              be both exciting and challenging. Our Job Search Assistance
              service is dedicated to empowering job seekers like you with the
              resources and support needed to navigate the job market
              successfully. We recognize that each individual's job search
              journey is unique, and our personalized approach ensures that you
              receive tailored guidance that aligns with your specific career
              goals and aspirations. Our team of experienced career advisors and
              job placement experts works closely with you to understand your
              skills, qualifications, and interests. By gaining insights into
              your professional background and career objectives, we can provide
              you with valuable advice on how to present yourself effectively to
              potential employers. Whether you're just starting your job search
              or are already in the process of exploring new opportunities, we
              offer practical advice on resume writing, cover letter crafting,
              and interview preparation. Your resume and cover letter are your
              first impressions on potential employers, and our expertise will
              help you showcase your strengths and achievements to stand out
              from other applicants.
            </h1>
          </div>
        </div>

        <div className="  p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 10 }}
            src={require("../images/srv4.jpg")}
          />
        </div>
      </section>
    </main>
  );
};

export default Services;
