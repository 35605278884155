import React from "react";
import startupImage from "../../images/Business-Growth-1.jpg";
import StartupForm from "./StartupForm";

const Startup = () => {
  return (
    <>
      <main className="bg-white min-h-screen flex items-center font-primary justify-center p-5">
        <div className="">
          <div className="container mx-auto bg-white lg:p-10 rounded-lg flex flex-col lg:flex-row justify-between items-center">
            <div className="lg:w-1/2 lg:mr-4">
              <h1 className="text-lg lg:text-lg font-bold text-left mb-4 lg:mb-8 leading-20 text-secondary">
                Empowering Startup Care Homes
              </h1>
              <p className="text-gray-700 text-left mb-4 text-sm lg:mb-8 leading-normal">
                Committed to empowering startup care homes, we aim to enhance
                individual well-being through innovative solutions. Our tailored
                approach includes resources, guidance, and a supportive network
                to navigate the care industry. With a dedicated team staying
                ahead of industry trends, we collaborate closely with partners
                to address specific challenges and create a future of excellence
                in care provision.
              </p>
            </div>

            <img
              src={startupImage}
              alt="Empowering Startup Care Homes"
              className="mb-4 lg:mb-8 w-full lg:w-1/2 "
            />
          </div>

          <section className="my-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <ServiceCard
                title="Tailored Support"
                description="Customized assistance for startup care homes"
              />
              <ServiceCard
                title="Specialized Services"
                description="Tailored services for semi-independent homes"
              />
              <ServiceCard
                title="Accommodation Solutions"
                description="Quality and comfortable living spaces"
              />
              <ServiceCard
                title="Comprehensive Support"
                description="A range of support services for care facilities"
              />
            </div>
          </section>
          {/* 
          <p className="mt-8 text-left text-gray-800">
            Whether you are establishing a new care facility or seeking support
            for an existing one, we are here to guide you through the process.
            Our team is committed to fostering a positive and nurturing
            environment for both residents and caregivers.
          </p> */}
        </div>
      </main>

      <div className="flex flex-col items-center justify-center my-10">
        <h1 className="text-lg font-primary text-primary">
          Let help build your startup
        </h1>

        <StartupForm />
      </div>
    </>
  );
};

const ServiceCard = ({ title, description }) => {
  return (
    <div className="bg-secondaryp p-8 rounded-md ">
      <h3 className="text-xl font-primary font-semibold mb-3 text-primary no-underline ">
        {title}
      </h3>
      <p className="font-primary text-secondary">{description}</p>
    </div>
  );
};

export default Startup;
