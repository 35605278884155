import { create } from "zustand";

const domain = "https://backend.ramadreams.com";
// / const domain = "http://localhost:9000";

export const useAuthStore = create((set) => ({
  // Load isLoggedIn state from localStorage if available
  isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
  user: [],
  jobs: [],
  jobDetails: [],
  application: [],
  loginUrl: `${domain}/api/v1/user`,
  applyJobUrl: `${domain}/api/v1/apply`,
  StartupFormUrl: `${domain}/api/v1/startup`,
  registrationUrl: `${domain}/api/v1/user/signup`,
  updateProfileUrl: `${domain}/api/v1/user/profilePicUpdate`,
  logoutUrl: `${domain}/api/v1/user/logout`,
  postJobUrl: `${domain}/api/v1/post`,
  getJobUrl: `${domain}/api/v1/post/jobs`,
  getYourJobsUrl: `${domain}/api/v1/post/get`,
  deleteJobUrl: `${domain}/api/v1/post/delete`,
  getlistOfJobUrl: `${domain}/api/v1/post/search`,
  resendEmail: `${domain}/api/v1/email_verification`,
  forgotPassword: `${domain}/api/v1/forgot_password`,
  resetPassword: `${domain}/api/v1/forgot_password/reset`,
  uploadProfileUrl: `${domain}/api/v1/upload/profile`,
  authPrivateDataUrl: `${domain}/api/v1/user/private_data`,

  setIsLoggedIn: (bool) => {
    // Set the state
    set({ isLoggedIn: bool });
    // Save isLoggedIn state to localStorage
    localStorage.setItem("isLoggedIn", bool.toString());
  },
  setUser: (obj) => set({ user: obj }),
  setApplication: (obj) => set({ application: obj }),
  setJobs: (obj) => set({ jobs: obj }),
  setJobDetails: (obj) => set({ jobDetails: obj }),

  updateProfilePicture: async (file) => {
    try {
      const formData = new FormData();
      formData.append("profilePicture", file);

      const response = await fetch(`${domain}/api/v1/upload/profile`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        set((state) => ({
          user: {
            ...state.user,
            profilePicture: data.profilePictureUrl,
          },
        }));
      } else {
        console.error("Failed to upload profile picture");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  },
}));
