import React, { useState } from "react";
import { MdDone} from "react-icons/md";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setFormStatus("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://rama-dreams-server.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowModal(true);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        setFormStatus("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      setFormStatus("An error occurred while sending the email.");
    }
  };

  return (
    
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      {/* Conditional rendering of the modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">

            <div className="doneBtn">
            <MdDone style={{ color: "#fff", fontSize: 30 }} />
            </div>

            <h2 style={{fontSize: 20}}>Email Sent Successfully!</h2>
            <p>Your email has been sent successfully.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
      <input
          type='name'
          name="name"
          placeholder="Full name"
          value={formData.name}
          onChange={handleChange}
          class="block w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-primary focus:border-blue-primary"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          class="block w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-primary focus:border-blue-primary"
        />

        <label className="text-left" class="block text-gray-700">Message:</label>
        <textarea
          placeholder="Write a message"
          rows={6}
          name="message"
          value={formData.message}
          onChange={handleChange}
          class="block w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-primary focus:border-blue-primary"
        />

        <button class="block w-full p-2 bg-primary text-white rounded-md hover:bg-secondary ">
          Submit
        </button>
      </form>
    </div>
  );
};
export default Form;
